<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />


    <!-- นำทาง -->
    <v-card elevation="0" class="v-card-bottom-30">
      <v-card-title class="body-1">
        ผู้ใช้
        <v-icon>mdi-chevron-right</v-icon>
        สถิติ
      </v-card-title>
    </v-card>

    <!-- ค้นหา -->
    <v-card elevation="1" class="v-card-margin-bottom-30">
      <v-card-title>
        <v-icon color="blue" size="24">fa-sliders-h</v-icon>
        <span class="ml-2">ค้นหาข้อมูล</span>
      </v-card-title>
      <v-divider/>
      <v-card-text>

        <v-row>
          <v-col cols="12" md="8" xl="6" lg="6">
            <v-row>
              <v-col cols="12" md="2"/>
              <v-col cols="12" md="2" class="pt-1 pb-2">
                วันที่ :
              </v-col>
              <v-col cols="12" md="3" class="pt-1 pb-2">
                <v-menu
                    ref="search_menu_date_from"
                    v-model="search_menu_date_from"
                    :close-on-content-click="false"
                    :return-value.sync="start_date"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                    <v-text-field
                        :value="computed_start_date_from"
                        @change="value => computed_start_date_from = value"
                        autocomplete="off"
                        label="เริ่ม"
                        outlined
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        hide-details
                        v-on="on"
                    ></v-text-field>
                    </template>
                    <v-date-picker v-model="start_date" @input="search_menu_date_from = false, $refs.search_menu_date_from.save(start_date)" no-title :allowed-dates="allowedDates" class="my-0">
                    </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="3" class="pt-1 pb-2">
                <v-menu
                    ref="search_menu_date_to"
                    v-model="search_menu_date_to"
                    :close-on-content-click="false"
                    :return-value.sync="end_date"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                    <v-text-field
                        :value="computed_end_date_to"
                        @change="value => computed_end_date_to = value"
                        autocomplete="off"
                        label="สิ้นสุด"
                        outlined
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        hide-details
                        v-on="on"
                    ></v-text-field>
                    </template>
                    <v-date-picker v-model="end_date" @input="search_menu_date_to = false, $refs.search_menu_date_to.save(end_date)" no-title :allowed-dates="allowedDates" class="my-0">
                    </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="3">
            <v-btn :loading="loading_btn" color="primary" class="pa-2 mt-1" @click=" get_staffbranch_packing_stat(), get_staffbranch_picking_stat()">
              <v-icon size="14" class="pr-1">fa-solid fa-magnifying-glass</v-icon>
              ค้นหา
            </v-btn>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>

    <v-card elevation="1" class="v-card-margin-bottom-30  text-center">
        <v-card-title>
            <span class="mr-3"> สถิติข้อมูลการแพ็ค</span>
            <v-spacer/>
            <vue-excel-xlsx
                :data="data_packing_stat_excel"
                :columns="data_packing_stat_excel_head"
                :file-name="'สถิติข้อมูลการแพ็ค'"
                :sheet-name="'สถิติข้อมูลการแพ็ค'"
                class="mr-2"
                >
                <v-btn :elevation="1" outlined color="success" class="pa-2">
                  <v-icon size="16" class="pr-1">fa-file-excel</v-icon>
                  <span class="fn-12"> Export Excel </span>
                </v-btn>
            </vue-excel-xlsx>
        </v-card-title>
        <v-divider/>
        <v-card-text>
            <v-row class="px-3">
              <v-simple-table class="elevation-0 packhai-border-table">
                <template v-slot:default>
                  <thead>
                    <tr align="left">
                      <th v-for="heads in data_packing_stat_head" :key="heads.name" align="left">
                        {{ heads }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="items in data_packing_stat" :key="items.name">
                      <td v-for="item_data in items" :key="item_data.name" align="left"> {{ item_data }} </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-row>
        </v-card-text>
    </v-card>


    <v-card elevation="1" class="v-card-margin-bottom-30  text-center">
        <v-card-title>
            <span class="mr-3"> สถิติข้อมูลการหยิบ</span>
            <v-spacer/>
            <vue-excel-xlsx
                :data="data_picking_stat_excel"
                :columns="data_picking_stat_excel_head"
                :file-name="'สถิติข้อมูลการหยิบ'"
                :sheet-name="'สถิติข้อมูลการหยิบ'"
                class="mr-2"
                >
                <v-btn :elevation="1" outlined color="success" class="pa-2">
                  <v-icon size="16" class="pr-1">fa-file-excel</v-icon>
                  <span class="fn-12"> Export Excel </span>
                </v-btn>
            </vue-excel-xlsx>
        </v-card-title>
        <v-divider/>
        <v-card-text>
            <v-row class="px-3">
              <v-simple-table class="elevation-0 packhai-border-table">
                <template v-slot:default>
                  <thead>
                    <tr align="left">
                      <th v-for="heads in data_picking_stat_head" :key="heads.name" align="left">
                        {{ heads }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="items in data_picking_stat" :key="items.name">
                      <td v-for="item_data in items" :key="item_data.name" align="left"> {{ item_data }} </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-row>
        </v-card-text>
    </v-card>

  </div>
</template>

<script>
  import axios from 'axios'
  import { header_token } from '@/website/token'
  import Loading from '@/website/components/Loading'
  // import { GChart } from 'vue-google-charts'
  import { reportService_dotnet, shopService_dotnet } from '@/website/global'
  import { format_price, formatMoney } from '@/website/global_function'
  export default {
    components: {
      Loading
    },
    data:() =>({
      page_loading: true,
      loading: false,
      loading_btn:false,
      isFullscreen : false,

      search_menu_date_from: false,
      search_menu_date_to: false,
      start_date: new Date(new Date().setDate(new Date().getDate()-10)).toISOString().substr(0, 10),
      end_date: new Date().toISOString().substr(0, 10),

      // data pack and pick
      data_packing_stat_head : [],
      data_packing_stat: [],

      data_packing_stat_excel_head : [],
      data_packing_stat_excel : [],

      data_picking_stat_head : [],
      data_picking_stat: [],
      data_picking_stat_excel_head : [],
      data_picking_stat_excel : [],


    }),
    async created() {
      this.branchID = localStorage.getItem('Branch_BranchID')
      this.get_staffbranch_packing_stat()
      this.get_staffbranch_picking_stat()
      this.page_loading = false
    },
    computed: {
      computed_start_date_from() {
        return this.formatDates(this.start_date)
      },
      computed_end_date_to() {
        return this.formatDates(this.end_date)
      },
    },
    methods:{
      format_price,
      allowedDates: val => val <= new Date().toISOString().substr(0, 10),
      formatDates(date) {
          if(date == null){
              return null
          } else {
          const [year, month, day] = date.split('-')
              return `${year}-${month}-${day}`
          }
      },

      async get_staffbranch_packing_stat () {
        this.loading = true
        let response = await axios.get(shopService_dotnet+'StaffBranch/get-staffbranch-packing-stat?BranchID=' + localStorage.getItem('Branch_BranchID') + '&from=' + this.start_date + ' 00:00:00' + '&to=' + this.end_date + ' 23:59:59', {headers: header_token})
        this.data_packing_stat_head = []
        this.data_packing_stat = []
        let dara_res = response.data.data

        this.data_packing_stat_excel = JSON.parse(JSON.stringify(dara_res))
        for (var i = 0; i < dara_res.length; i++) {
          if(i == 0){
            this.data_packing_stat_head = dara_res[i]
          }else {
            this.data_packing_stat.push(dara_res[i])
          }
        }
        this.loading = false

        // set data excel
        this.data_packing_stat_excel_head = []
        var head_t = 1
        for (var head_1 of this.data_packing_stat_head) {
          this.data_packing_stat_excel_head.push({ label: head_1, field: "h" + head_t.toString()})
          head_t += 1
        }

        this.data_packing_stat_excel = []
        var data_excel_tmp = []
        var data_t = 1
        for (var data_1 of this.data_packing_stat) {
            data_t = 1
            data_excel_tmp = []
            var dict = {}
            for (var data2 of data_1) {
              var htmp = "h" + data_t.toString() ;
              dict[htmp] = data2
              data_t += 1

            }
            this.data_packing_stat_excel.push(JSON.parse(JSON.stringify(dict)))
        }
      },

      async get_staffbranch_picking_stat () {
        this.loading = true
        let response = await axios.get(shopService_dotnet+'StaffBranch/get-staffbranch-picking-stat?BranchID=' + localStorage.getItem('Branch_BranchID') + '&from=' + this.start_date + ' 00:00:00' + '&to=' + this.end_date + ' 23:59:59', {headers: header_token})
        this.data_picking_stat_head = []
        this.data_picking_stat = []
        let dara_res = response.data.data
        this.data_picking_stat_excel = JSON.parse(JSON.stringify(dara_res))
        for (var i = 0; i < dara_res.length; i++) {
          if(i == 0){
            this.data_picking_stat_head = dara_res[i]
          }else {
            this.data_picking_stat.push(dara_res[i])
          }
        }

        this.loading = false

        // set data excel
        this.data_picking_stat_excel_head = []
        var head_t = 1
        for (var head_1 of this.data_picking_stat_head) {
          this.data_picking_stat_excel_head.push({ label: head_1, field: "h" + head_t.toString()})
          head_t += 1
        }

        this.data_picking_stat_excel = []
        var data_excel_tmp = []
        var data_t = 1
        for (var data_1 of this.data_picking_stat) {
            data_t = 1
            data_excel_tmp = []
            var dict = {}
            for (var data2 of data_1) {
              var htmp = "h" + data_t.toString() ;
              dict[htmp] = data2
              data_t += 1

            }
            this.data_picking_stat_excel.push(JSON.parse(JSON.stringify(dict)))
        }


      },

      formatMoney
    },
  }
</script>
